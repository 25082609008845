<template>
  <Card
    :has-body-padding-small="true"
  >
    <template #body>
      <table
        v-if="canShowFirstTable"
        :class="tableClass"
      >
        <thead>
          <tr>
            <th :class="[thClass, 'tw-w-2/5']">
              &nbsp;
            </th>
            <th :class="[thClass, 'tw-w-1/5']">
              Current
            </th>
            <th :class="[thClass, 'tw-w-1/5']">
              <span>Max</span>
              <span class="tw-block md:tw-inline">(24 hr)</span>
            </th>
            <th :class="[thClass, 'tw-w-1/5']">
              <span>Min</span>
              <span class="tw-block md:tw-inline">(24 hr)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="({ isLocked, label, value }) in tableRowsFirstTable"
            :key="label"
          >
            <td :class="tdClass">
              <strong :class="labelClass">
                {{ label }}
              </strong>
            </td>
            <td :class="[tdClass, 'tw-text-center']">
              <font-awesome-icon
                v-if="isLocked"
                class="all-access-color"
                icon="lock"
              />
              <span v-else>
                {{ value.current }}
              </span>
            </td>
            <td :class="[tdClass, 'tw-text-center']">
              <font-awesome-icon
                v-if="isLocked"
                class="all-access-color"
                icon="lock"
              />
              <span v-else>
                {{ value.max }}
              </span>
            </td>
            <td :class="[tdClass, 'tw-text-center']">
              <font-awesome-icon
                v-if="isLocked"
                class="all-access-color"
                icon="lock"
              />
              <span v-else>
                {{ value.min }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        v-if="canShowSecondTable"
        :class="[tableClass, inAppView ? 'tw-mt-2.5' : 'tw-mt-8']"
      >
        <thead>
          <tr>
            <th :class="[thClass, 'tw-w-2/5']">
              &nbsp;
            </th>
            <th :class="[thClass, 'tw-w-1/5']">
              <span>Last</span>
              <span class="tw-block md:tw-inline">6 hrs</span>
            </th>
            <th :class="[thClass, 'tw-w-1/5']">
              <span>Last</span>
              <span class="tw-block md:tw-inline">12 hrs</span>
            </th>
            <th :class="[thClass, 'tw-w-1/5']">
              <span>Last</span>
              <span class="tw-block md:tw-inline">24 hrs</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="({ isLocked, label, value }) in tableRowsSecondTable"
            :key="label"
          >
            <td :class="tdClass">
              <strong :class="labelClass">
                {{ label }}
              </strong>
            </td>
            <td :class="[tdClass, 'tw-text-center']">
              <font-awesome-icon
                v-if="isLocked"
                class="all-access-color"
                icon="lock"
              />
              <span v-else>
                {{ value.last6Hours }}
              </span>
            </td>
            <td :class="[tdClass, 'tw-text-center']">
              <font-awesome-icon
                v-if="isLocked"
                class="all-access-color"
                icon="lock"
              />
              <span v-else>
                {{ value.last12Hours }}
              </span>
            </td>
            <td :class="[tdClass, 'tw-text-center']">
              <font-awesome-icon
                v-if="isLocked"
                class="all-access-color"
                icon="lock"
              />
              <span v-else>
                {{ value.last24Hours }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <WeatherStationNoData
        v-if="!canShowFirstTable && !canShowSecondTable"
      />
      <WeatherStationFooter />
    </template>
  </Card>
</template>

<script>
/* eslint camelcase: off */

import { mapState } from 'pinia';
import {
  formatRelativeHumidity,
  formatSnow,
  formatTemp,
  formatWind,
  safeRound,
} from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';
import AppViewMixin from '@@/utils/AppViewMixin';
import WeatherStationMixin from '@@/components/WeatherStations/WeatherStationMixin';

export default {
  name: 'WeatherStationCurrentConditions',

  mixins: [AppViewMixin, WeatherStationMixin],

  computed: {
    ...mapState(useUserStore, ['isAllAccess']),
    ...mapState(useUserStore, { units: (state) => state.preferences.units }),

    canShowFirstTable() {
      return this.hasTemp
        || this.hasRelativeHumidity
        || this.hasWindSpeed
        || this.hasWindGustSpeed
        || this.hasSnowDepth
        || this.hasSwe;
    },

    canShowSecondTable() {
      return this.hasPrecipSnow;
    },

    labelClass() {
      return this.inAppView ? 'tw-text-sm tw-font-medium' : 'tw-text-xs tw-font-bold';
    },

    observationSummary12hr() {
      return this.getObservationSummaryByHours(12);
    },

    observationSummary24hr() {
      return this.getObservationSummaryByHours(24);
    },

    observationSummary6hr() {
      return this.getObservationSummaryByHours(6);
    },

    precipSnow() {
      return this.getSecondTableSnowData('precip_snow');
    },

    relativeHumidity() {
      return {
        current: formatRelativeHumidity(this.observationSummary24hr.relative_humidity, true),
        max: formatRelativeHumidity(this.observationSummary24hr.relative_humidity_max, true),
        min: formatRelativeHumidity(this.observationSummary24hr.relative_humidity_min, true),
      };
    },

    snowDepth() {
      return {
        current: formatSnow(this.observationSummary24hr.snow_depth, this.units),
        max: formatSnow(this.observationSummary24hr.snow_depth_max, this.units),
        min: formatSnow(this.observationSummary24hr.snow_depth_min, this.units),
      };
    },

    swe() {
      return {
        current: formatSnow(this.observationSummary24hr.swe, this.units),
        max: formatSnow(this.observationSummary24hr.swe_max, this.units),
        min: formatSnow(this.observationSummary24hr.swe_min, this.units),
      };
    },

    tableClass() {
      return `tw-table-fixed tw-border-collapse tw-w-full ${this.inAppView ? 'tw-mb-2.5' : 'tw-my-2.5'}`;
    },

    tableRowsFirstTable() {
      const isLocked = !this.isAllAccess;
      const tableRows = [];

      if (this.hasTemp) {
        tableRows.push({ label: 'Temperature', value: this.temp });
      }

      if (this.hasRelativeHumidity) {
        tableRows.push({ isLocked, label: 'Relative Humidity', value: this.relativeHumidity });
      }

      if (this.hasWindSpeed) {
        tableRows.push({ isLocked, label: 'Wind Speed', value: this.windSpeed });
      }

      if (this.hasWindGustSpeed) {
        tableRows.push({ isLocked, label: 'Wind Gust Speed', value: this.windGustSpeed });
      }

      if (this.hasSnowDepth) {
        tableRows.push({ isLocked, label: 'Snow Depth', value: this.snowDepth });
      }

      if (this.hasSwe) {
        tableRows.push({ isLocked, label: 'Snow Water Equivalent (SWE)', value: this.swe });
      }

      return tableRows;
    },

    tableRowsSecondTable() {
      const isLocked = !this.isAllAccess;
      const tableRows = [];

      if (this.hasPrecipSnow) {
        tableRows.push({ isLocked, label: 'Snowfall', value: this.precipSnow });
      }

      return tableRows;
    },

    tdClass() {
      return 'tw-py-1.5 tw-text-sm tw-border-t border-top-color';
    },

    temp() {
      return {
        current: formatTemp(this.observationSummary24hr.temp, this.units),
        max: formatTemp(this.observationSummary24hr.temp_max, this.units),
        min: formatTemp(this.observationSummary24hr.temp_min, this.units),
      };
    },

    thClass() {
      return [
        'tw-py-0.5',
        'tw-text-xs tw-font-bold',
        this.inAppView ? 'text-darkest-color' : 'text-light-color',
        'tw-align-bottom',
      ].join(' ');
    },

    windGustSpeed() {
      return {
        current: formatWind(this.observationSummary24hr.wind_gust_speed, this.units),
        max: formatWind(this.observationSummary24hr.wind_gust_speed_max, this.units),
        min: formatWind(this.observationSummary24hr.wind_gust_speed_min, this.units),
      };
    },

    windSpeed() {
      return {
        current: formatWind(this.observationSummary24hr.wind_speed, this.units),
        max: formatWind(this.observationSummary24hr.wind_speed_max, this.units),
        min: formatWind(this.observationSummary24hr.wind_speed_min, this.units),
      };
    },
  },

  methods: {
    getObservationSummaryByHours(hours) {
      return this.weatherStation.observation_summary
        .find(({ duration_hours }) => duration_hours === hours);
    },

    getSecondTableSnowData(field, canRound = true) {
      return {
        last6Hours: formatSnow(
          canRound
            ? safeRound(this.observationSummary6hr[field])
            : this.observationSummary6hr[field],
          this.units,
          true,
        ),
        last12Hours: formatSnow(
          canRound
            ? safeRound(this.observationSummary12hr[field])
            : this.observationSummary12hr[field],
          this.units,
          true,
        ),
        last24Hours: formatSnow(
          canRound
            ? safeRound(this.observationSummary24hr[field])
            : this.observationSummary24hr[field],
          this.units,
          true,
        ),
      };
    },
  },
};
</script>
