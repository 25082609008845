<template>
  <footer class="tw-my-4 tw-text-center tw-text-sm tw-font-normal text-dark-color">
    <small>
      Weather station data provided by
      <NuxtLink
        class="tw-underline link-color-brand"
        :external="true"
        target="_blank"
        to="https://synopticdata.com/"
      >Synoptic Data</NuxtLink>.
    </small>
  </footer>
</template>
