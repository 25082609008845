import { mapState } from 'pinia';
import { useMetaStore } from '@@/stores/Meta';

export default {
  computed: {
    ...mapState(useMetaStore, [
      'getSnowObservationAlert',
      'getSuspectObservationAlert',
      'snowObservationAlert',
      'suspectObservationAlert',
    ]),

    snowObservationAlertMixLevel() {
      return this.snowObservationAlert.levels[0];
    },

    snowObservationAlertPowderLevel() {
      return this.snowObservationAlert.levels[2];
    },

    snowObservationAlertSnowLevel() {
      return this.snowObservationAlert.levels[1];
    },
  },

  methods: {
    hasMix(period = {}) {
      const snowObservationAlert = this.getSnowObservationAlert(period.alerts);
      return snowObservationAlert?.level_id === this.snowObservationAlertMixLevel.id;
    },

    hasNoPrecip(period = {}) {
      const snowObservationAlert = this.getSnowObservationAlert(period.alerts);
      return !snowObservationAlert || period?.precip_snow === 0;
    },

    hasPowder(period = {}) {
      const snowObservationAlert = this.getSnowObservationAlert(period.alerts);
      return snowObservationAlert?.level_id === this.snowObservationAlertPowderLevel.id;
    },

    hasSnow(period = {}) {
      const snowObservationAlert = this.getSnowObservationAlert(period.alerts);
      return snowObservationAlert?.level_id === this.snowObservationAlertSnowLevel.id;
    },

    hasSuspectObservation(period = {}) {
      const suspectObservationAlert = this.getSuspectObservationAlert(period.alerts);
      return suspectObservationAlert;
    },
  },
};
