import { useUserStore } from '@@/stores/User';
import { useWeatherStationStore } from '@@/stores/WeatherStation';

export const availableHours = [24, 48, 72, 120, 168];
export const availableViews = ['current-conditions', 'charts', 'data'];

export const useWeatherStation = (params = {}) => {
  const {
    defaultHours = 48,
    defaultView = 'charts',
    slug,
  } = params;

  if (!availableHours.includes(defaultHours)) {
    throw new Error('Invalid default hours passed to useWeatherStation()!');
  }

  if (!availableViews.includes(defaultView)) {
    throw new Error('Invalid default view passed to useWeatherStation()');
  }

  const hours = ref(defaultHours);
  const view = ref(defaultView);
  const weatherStation = ref(null);

  const userStore = useUserStore();
  const weatherStationStore = useWeatherStationStore();

  const units = computed(() => userStore.preferences.units);

  const fetchWeatherStation = async () => weatherStationStore.fetchWeatherStation({
    hours: hours.value,
    slug,
    units: units.value,
  });

  const setWeatherStation = async () => {
    weatherStation.value = await fetchWeatherStation();
  };

  watch(hours, setWeatherStation);
  watch(units, setWeatherStation);

  return {
    fetchWeatherStation,
    hours,
    view,
    weatherStation,
  };
};
