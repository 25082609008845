export default {
  props: {
    hours: {
      type: Number,
      default: 24,
    },
    weatherStation: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasPrecipSnow() {
      return this.hasStationVariable('precip_snow');
    },

    hasRelativeHumidity() {
      return this.hasStationVariable('relative_humidity');
    },

    hasSlr() {
      return this.hasStationVariable('slr');
    },

    hasSnowDepth() {
      return this.hasStationVariable('snow_depth');
    },

    hasSnowInterval() {
      return this.hasStationVariable('snow_interval');
    },

    hasSwe() {
      return this.hasStationVariable('swe');
    },

    hasTemp() {
      return this.hasStationVariable('temp');
    },

    hasWindGustSpeed() {
      return this.hasStationVariable('wind_gust_speed');
    },

    hasWindDir() {
      return this.hasWindGustSpeed || this.hasWindSpeed;
    },

    hasWindSpeed() {
      return this.hasStationVariable('wind_speed');
    },

    observation() {
      return this.weatherStation?.observation;
    },
  },

  methods: {
    hasStationVariable(variable) {
      const index = this.weatherStation
        ?.station_variables
        ?.findIndex(({ name }) => name === variable);

      return index >= 0;
    },
  },
};
