/* eslint camelcase: 0 */
import { defineStore } from 'pinia';

export const useWeatherStationStore = defineStore('weatherStation', {
  state: () => ({}),

  actions: {
    async fetchWeatherStation(payload = {}) {
      const { $api } = useNuxtApp();
      const { hours = 48, slug, units = 'imperial' } = payload;
      const url = `/weather-stations/${slug}`;
      const { weather_station } = await $api.get(url, { hours, units });
      return weather_station;
    },
  },
});
