<script setup>
import { getMaskImageStyle } from '@@/utils/CommonUtils';

const backgroundImageStyle = {
  backgroundColor: 'currentColor',
  ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/duotone/tower-cell.svg'),
};
</script>

<template>
  <p class="tw-mb-4 tw-text-normal tw-text-center text-regular-color">
    <span
      class="tw-block tw-h-12 tw-w-12 tw-mx-auto tw-mb-3"
      :style="backgroundImageStyle"
    />
    No data found for this weather station.
  </p>
</template>
