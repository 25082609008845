<template>
  <Card
    card-class-names="tw-pt-4 lg:tw-pt-6"
    :has-body-padding-small="true"
    header-class-name="tw-px-2 tw-py-4"
  >
    <template #body>
      <div class="tw-w-full tw-overflow-x-auto hide-scrollbar-on-mobile">
        <table
          v-if="hasData"
          class="tw-table-fixed tw-border-collapse tw-w-full tw-my-2.5"
          :style="tableStyle"
        >
          <thead>
            <tr>
              <th
                :class="[thClass, 'tw-sticky tw-left-0 tw-z-10 card-background-color']"
                :style="thDateColumnStyle"
              >
                &nbsp;
              </th>
              <th
                v-if="hasTemp"
                :class="thClass"
                :style="thColumnStyle"
              >
                Temp {{ tempUnits }}
              </th>
              <th
                v-if="hasRelativeHumidity"
                :class="thClass"
                :style="thColumnStyle"
              >
                RH%
              </th>
              <th
                v-if="hasWindSpeed"
                :class="thClass"
                :style="thColumnStyle"
              >
                <span>Wind<br>Speed ({{ windUnits }})</span>
              </th>
              <th
                v-if="hasWindGustSpeed"
                :class="thClass"
                :style="thColumnStyle"
              >
                <span>Wind Gust<br>Speed ({{ windUnits }})</span>
              </th>
              <th
                v-if="hasWindDir"
                :class="thClass"
                :style="thWindDirColumnStyle"
              >
                <span>Wind<br>Direction</span>
              </th>
              <th
                v-if="hasPrecipSnow"
                :class="thClass"
                :style="thColumnStyle"
              >
                <span>Precip<br>Snow ({{ snowUnits }})</span>
              </th>
              <th
                v-if="hasSnowDepth"
                :class="thClass"
                :style="thColumnStyle"
              >
                <span>Snow<br>Depth ({{ snowUnits }})</span>
              </th>
              <th
                v-if="hasSwe"
                :class="thClass"
                :style="thColumnStyle"
              >
                SWE ({{ snowUnits }})
              </th>
              <th
                v-if="hasSlr"
                :class="thClass"
                :style="thColumnStyle"
              >
                SLR
              </th>
              <th
                v-if="hasSnowInterval"
                :class="thClass"
                :style="thColumnStyle"
              >
                <span>Snow<br>Interval ({{ snowUnits }})</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(tableRow, rowIndex) in tableRows"
              :key="getKey(rowIndex)"
            >
              <td
                v-for="({ className, isLocked, value }, colIndex) in tableRow"
                :key="getKey(rowIndex, colIndex)"
                :class="getTdClass(colIndex, rowIndex, className)"
              >
                <font-awesome-icon
                  v-if="isLocked"
                  class="all-access-color"
                  icon="lock"
                />
                <span
                  v-else
                  class="tw-text-sm"
                >
                  {{ value }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <WeatherStationNoData
          v-if="!hasData"
        />
      </div>
      <WeatherStationFooter />
    </template>
  </Card>
</template>

<script>
/* eslint camelcase: off */
import { mapState } from 'pinia';
import {
  formatSlr,
  getSnowUnits,
  getTemperatureUnits,
  getWindUnits,
  safeRound,
} from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';
import AppViewMixin from '@@/utils/AppViewMixin';
import WeatherStationMixin from '@@/components/WeatherStations/WeatherStationMixin';

const dateColumnWidth = 105;
const windDirColumnWidth = 95;

export default {
  name: 'WeatherStationData',
  mixins: [AppViewMixin, WeatherStationMixin],

  computed: {
    ...mapState(useUserStore, ['isAllAccess']),
    ...mapState(useUserStore, { units: (state) => state.preferences.units }),

    hasData() {
      return this.tableRows.length > 0;
    },

    snowUnits() {
      return getSnowUnits(this.units);
    },

    stationVariablesCount() {
      const stationVariablesCount = this.weatherStation.station_variables
        .filter(({ name }) => name !== 'precip_snow_sum').length;
      return this.hasWindDir ? stationVariablesCount + 1 : stationVariablesCount;
    },

    tableRows() {
      const { timezone } = this.weatherStation;
      // Not using the standard "N/A" here which would be too distracting in the table.
      const dataNotAvailable = '--';

      const isLocked = !this.isAllAccess;

      const isNumeric = (value) => typeof value === 'number';
      const formatNumberToOneDecimalIfNumeric = (value) => (
        isNumeric(value) ? Number(value).toFixed(1) : dataNotAvailable
      );
      const formatRoundIfNumeric = (value) => (
        isNumeric(value) ? Math.round(value) : dataNotAvailable
      );

      const tableRows = this.observation.map((observation) => {
        const {
          precip_snow,
          relative_humidity,
          slr,
          snow_depth,
          snow_interval,
          swe,
          temp,
          wind_dir,
          wind_dir_label,
          wind_gust_speed,
          wind_speed,
        } = observation;

        const tableRow = [];

        const dateTime = this.$dayjs.utc(observation.display_at).tz(timezone).format('ddd h:mma');
        tableRow.push({ value: dateTime });

        if (this.hasTemp) {
          tableRow.push({ value: formatRoundIfNumeric(temp) });
        }

        if (this.hasRelativeHumidity) {
          tableRow.push({ isLocked, value: formatRoundIfNumeric(relative_humidity) });
        }

        if (this.hasWindSpeed) {
          tableRow.push({ isLocked, value: formatRoundIfNumeric(wind_speed) });
        }

        if (this.hasWindGustSpeed) {
          tableRow.push({ isLocked, value: formatRoundIfNumeric(wind_gust_speed) });
        }

        if (this.hasWindDir) {
          if (wind_dir_label && typeof wind_dir === 'number') {
            tableRow.push({
              className: 'tw-whitespace-nowrap',
              isLocked,
              value: `${wind_dir_label} (${safeRound(wind_dir)}°)`,
            });
          }
          else {
            tableRow.push({ isLocked, value: dataNotAvailable });
          }
        }

        if (this.hasPrecipSnow) {
          tableRow.push({ isLocked, value: formatRoundIfNumeric(precip_snow) });
        }

        if (this.hasSnowDepth) {
          tableRow.push({ isLocked, value: formatRoundIfNumeric(snow_depth) });
        }

        if (this.hasSwe) {
          tableRow.push({ isLocked, value: formatNumberToOneDecimalIfNumeric(swe) });
        }

        if (this.hasSlr) {
          tableRow.push({ isLocked, value: isNumeric(slr) ? formatSlr(slr) : dataNotAvailable });
        }

        if (this.hasSnowInterval) {
          tableRow.push({ isLocked, value: formatNumberToOneDecimalIfNumeric(snow_interval) });
        }

        return tableRow;
      });

      return tableRows.reverse();
    },

    tableStyle() {
      let minWidth = `${Math.round((this.stationVariablesCount * this.thColumnWidth) + dateColumnWidth)}px`;

      if (this.hasWindDir) {
        minWidth = `${Math.round(((this.stationVariablesCount - 1) * this.thColumnWidth) + dateColumnWidth + windDirColumnWidth)}px`;
      }

      return { minWidth };
    },

    tdClass() {
      return [
        'tw-h-6',
        'tw-py-1.5 tw-pl-1 tw-pr-4',
        'tw-border-t tw-border-b border-color',
        'tw-align-middle',
      ];
    },

    thClass() {
      return [
        'tw-pb-1.5 tw-pl-1 tw-pr-4',
        'tw-text-right tw-align-bottom tw-whitespace-nowrap',
        'tw-text-xs tw-font-bold',
        this.inAppView ? 'text-darkest-color' : 'text-light-color',
      ];
    },

    thDateColumnStyle() {
      return { width: `${dateColumnWidth}px` };
    },

    thColumnStyle() {
      let minWidth = `calc(${((1 / this.stationVariablesCount) * 100)}% - ${dateColumnWidth}px)`;

      if (this.hasWindDir) {
        minWidth = `calc(${((1 / (this.stationVariablesCount - 1)) * 100)}% - ${dateColumnWidth}px -${windDirColumnWidth}px)`;
      }
      const width = `${this.thColumnWidth}px`;
      return { minWidth, width };
    },

    thColumnWidth() {
      return this.hasWindSpeed || this.hasSnowInterval ? 85 : 70;
    },

    thWindDirColumnStyle() {
      const minWidth = `calc(${((1 / this.stationVariablesCount) * 100)}% - ${dateColumnWidth}px)`;
      const width = `${windDirColumnWidth}px`;
      return { minWidth, width };
    },

    tempUnits() {
      return getTemperatureUnits(this.units);
    },

    windUnits() {
      return getWindUnits(this.units);
    },
  },

  methods: {
    getKey(rowIndex, colIndex) {
      const index = this.observation.length - (rowIndex + 1);
      const { display_at } = this.observation[index];

      if (typeof colIndex === 'undefined') {
        return display_at;
      }

      return `${display_at}-${colIndex}`;
    },

    getTdClass(colIndex, rowIndex, className) {
      const index = this.observation.length - (rowIndex + 1);
      const { day_period } = this.observation.at(index);

      return [
        ...this.tdClass,
        day_period === 'night' ? 'card-alternate-row-background-color' : '',
        colIndex === 0
          ? 'tw-sticky tw-left-0 tw-z-10 tw-text-left tw-font-medium tw-whitespace-nowrap'
          : 'tw-text-right tw-font-normal',
        colIndex === 0 && day_period === 'day' ? 'card-background-color' : '',
        className ?? '',
      ];
    },
  },
};
</script>
