<template>
  <div :class="['tw-flex tw-items-center', containerClass]">
    <label :class="labelClass">
      Timeframe:
    </label>
    <Chips
      :can-show-chip-active-icon="false"
      :multiple="false"
      :no-wrap="true"
      :options="options"
      :should-scroll-active-item-into-view="true"
      :model-value="[modelValue]"
      @update:model-value="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: 'WeatherStationHours',

  props: {
    containerClass: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    labelClass() {
      return [
        'tw-mr-2 tw-pb-0.5',
        'tw-text-sm tw-font-bold text-regular-color',
        'tw-whitespace-nowrap',
      ];
    },

    options() {
      return [
        { label: '24 hours', value: 24 },
        { label: '48 hours', value: 48 },
        { label: '3 days', value: 72 },
        { label: '5 days', value: 120 },
        { label: '7 days', value: 168 },
      ];
    },
  },

  methods: {
    handleInput(values) {
      this.$emit('update:modelValue', values[0]);
    },
  },
};
</script>
