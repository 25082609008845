<script setup>
import { dataNotAvailable, formatElevation } from '@@/utils/CommonUtils';
import { useInAppView } from '@@/composables/useInAppView';
import { useUiStore } from '@@/stores/Ui';
import { useUserStore } from '@@/stores/User';

const props = defineProps({
  isPageHeader: {
    type: Boolean,
    default: false,
  },
  inLocationPage: {
    type: Boolean,
    default: false,
  },
  inMapPage: {
    type: Boolean,
    default: false,
  },
  inWeatherStationDialog: {
    type: Boolean,
    default: false,
  },
  showWeatherStationDialog: {
    type: Boolean,
    default: false,
  },
  weatherStation: {
    type: Object,
    required: true,
  },
});

const uiStore = useUiStore();
const userStore = useUserStore();

const units = computed(() => userStore.preferences.units);

const coordinates = computed(() => {
  const [lng, lat] = props.weatherStation.coordinates.point;
  return `${lat}, ${lng}`;
});

const detailsClass = 'tw-text-xs lg:tw-text-sm tw-overflow-hidden tw-whitespace-nowrap tw-overflow-ellipsis';
// TODO: Make stationInfoClass a computed prop and consolidate duplicate code seen in markup.
const stationInfoClass = 'tw-text-sm lg:tw-text-base';

const elevation = computed(() => formatElevation(props.weatherStation.elevation, units.value));
const inAppView = computed(() => useInAppView());

const mapUrl = computed(() => {
  const [lng, lat] = props.weatherStation.coordinates.point;
  return `/map/none/weather-stations/@${lat},${lng},12.0000z`;
});

const name = computed(() => props.weatherStation?.name || dataNotAvailable);
const networkName = computed(() => props.weatherStation?.network_name || dataNotAvailable);
const relativeLocationLabel = computed(() => props.weatherStation?.relative_location?.relative_label
  || dataNotAvailable);

const size = computed(() => {
  if (props.inWeatherStationDialog) return 'medium';
  if (props.isPageHeader) return 'large';
  return 'small';
});

const showMapLink = computed(() => {
  if (inAppView.value || props.inMapPage) {
    return false;
  }

  return true;
});

const showRelativeLocation = computed(() => {
  if (props.inWeatherStationDialog) {
    return false;
  }

  if (props.inLocationPage && props.weatherStation?.relative_location) {
    return true;
  }

  return false;
});

const showStationLink = computed(() => {
  if (props.isPageHeader || props.inWeatherStationDialog || inAppView.value) {
    return false;
  }

  return true;
});

const weatherStationSlug = computed(() => props.weatherStation?.slug);

const weatherStationUrl = computed(() => {
  if (props.showWeatherStationDialog) {
    return 'javascript:void(0);';
  }

  return `/weather-stations/${props.weatherStation?.slug}?hours=48&view=charts`;
});

const handleWeatherStationLinkClick = (event) => {
  if (inAppView.value) {
    return;
  }

  if (props.showWeatherStationDialog) {
    event.preventDefault();
    event.stopImmediatePropagation();
    uiStore.setUiProperties({ weatherStationSlug: weatherStationSlug.value });
  }
};
</script>

<template>
  <ResourceHeader
    container-class="tw-items-center"
    details-class="tw-whitespace-nowrap"
    :size="size"
  >
    <template #title>
      <NuxtLink
        v-if="showStationLink"
        class="tw-text-base link-color-brand"
        :to="weatherStationUrl"
        @click="handleWeatherStationLinkClick"
      >
        {{ name }}
      </NuxtLink>
      <span
        v-else
        :class="{ 'tw-text-2xl': inAppView }"
      >
        {{ name }}
      </span>
    </template>
    <template
      v-if="isPageHeader"
      #subtitle
    >
      <NuxtLink
        v-if="showMapLink"
        :to="mapUrl"
        :class="[
          'tw-inline-block tw-mt-2',
          stationInfoClass,
          'link-color-brand',
        ]"
      >
        {{ networkName }} • {{ elevation }} • {{ coordinates }}
      </NuxtLink>
      <span
        v-else
        :class="[
          'tw-block tw-mt-2',
          stationInfoClass,
        ]"
      >
        {{ networkName }} • {{ elevation }} • {{ coordinates }}
      </span>
    </template>
    <template
      v-if="!isPageHeader"
      #details
    >
      <div :class="detailsClass">
        <NuxtLink
          v-if="showMapLink"
          :to="mapUrl"
          :class="[
            'tw-inline-block',
            inWeatherStationDialog ? 'tw-mt-1 lg:tw-mt-0' : null,
            inWeatherStationDialog ? stationInfoClass : null,
            'link-color-brand',
          ]"
        >
          {{ networkName }} • {{ elevation }} • {{ coordinates }}
        </NuxtLink>
        <span
          v-else
          :class="[
            'tw-block',
            inWeatherStationDialog ? 'tw-mt-1 lg:tw-mt-0' : null,
            stationInfoClass,
          ]"
        >
          {{ networkName }} • {{ elevation }} • {{ coordinates }}
        </span>
      </div>
      <div
        v-if="showRelativeLocation"
        :class="[detailsClass, 'text-regular-color']"
      >
        Located: {{ relativeLocationLabel }}
      </div>
      <div
        v-if="inWeatherStationDialog"
        :class="detailsClass"
      >
        <NuxtLink
          :to="weatherStationUrl"
          class="tw-text-sm lg:tw-text-base link-color-brand"
          target="blank"
        >
          Direct Link
          <font-awesome-icon
            class="tw-ml-0.5"
            :icon="['fas', 'arrow-alt-circle-right']"
          />
        </NuxtLink>
      </div>
    </template>
  </ResourceHeader>
</template>
